import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
declare var $: any;

@Injectable()
export class ApiService {

  constructor(private _http: HttpClient) {}

  postIntervalAPI(_url, _action: string, _body: any, buttonId?: string) {
    this.addLoader(buttonId);
    return this._http
      .post(_url + _action, JSON.stringify(_body), this.addHeader())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorIntervalHandler(error);
        })
      );
  }

  _errorIntervalHandler(error) {
    let errorMessage;
    if (!error || !error.status) {
      errorMessage = 'Server Not Responding';
    } else if (error.status === 401) {
      errorMessage = (error.error.message) || 'Bad Response';
    } else if (error.status === 500) {
      errorMessage = 'Server Error';
    } else {
      errorMessage = (error.error.message) || 'Bad Response';
    }
    return of(errorMessage);
  }

  _loginErrorHandler(error) {
    let errorMessage;
    if (!error || !error.status) {
      errorMessage = 'Server Not Responding';
    } else if (error.status === 401 || error.status === 400) {
      errorMessage = (error.error.message) || 'Invalid Username or Password';
    } else if (error.status === 500) {
      errorMessage = 'Server Error';
    } else {
      errorMessage = (error.error.message) || 'Bad Response';
    }

    $.notify(
      {
        title: '<strong>Error</strong>',
        message: errorMessage
      },
      {
        type: 'danger'
      }
    );

    return of(errorMessage);
  }

  getAPI(_url, _action: string, _body?: any, buttonId?: string) {
    const params = {};
    if (_body) {
      for (const key in _body) {
        if (Array.isArray(_body[key])) {
          params[key] =  JSON.stringify(_body[key]);
        } else {
          params[key] = _body[key];
        }
      }
    }
    this.addLoader(buttonId);
    return this._http
      .get(_url + _action, { params: _body, headers: this.getHeader() })
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  postAPI(_url, _action: string, _body: any, buttonId?: string) {
    this.addLoader(buttonId);
    return this._http
      .post(_url + _action, JSON.stringify(_body), this.addHeader())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  postFormAPI(_url, _action: string, _body: any, buttonId?: string) {
    this.addLoader(buttonId);
    return this._http
      .post(_url + _action, _body, this.addHeaderForMultipart())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  putAPI(_url, _action: string, _body: any, buttonId?: string) {
    this.addLoader(buttonId);
    return this._http
      .put(_url + _action, JSON.stringify(_body), this.addHeader())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  deleteAPI(_url, _action: string, _id?: string, buttonId?: string) {
    this.addLoader(buttonId);
    return this._http
      .delete(_url + _action, this.addHeader())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  uploadImageAPI(
    _url,
    _action: string,
    _file: File,
    _propertyAgreementId?: string,
    _fileTypeId?: string,
    _name?: string,
    buttonId?: string
  ) {
    this.addLoader(buttonId);
    const formData = new FormData();
    formData.append('file', _file);
    if (_propertyAgreementId != null) {
      formData.append('propertyAgreementId', _propertyAgreementId.toString());
    }
    if (_fileTypeId != null) {
      formData.append('fileTypeId', _fileTypeId.toString());
    }
    if (_name != null) {
      formData.append('fileName', _name.toString());
    }
    return this._http
      .post(_url + _action, formData, this.addHeaderForMultipart())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  removeImageAPI(_url, _action: string, _index: number, _id: number, buttonId?: string) {
    this.addLoader(buttonId);
    const formData = new FormData();
    formData.append('index', _index.toString());
    formData.append('id', _id.toString());
    return this._http
      .post(_url + _action, formData, this.addHeaderForMultipart())
      .pipe(
        map((response: any) => {
          this.removeLoader(buttonId);
          return response;
        }),
        catchError((error) => {
          this.removeLoader(buttonId);
          return this._errorHandler(error);
        })
      );
  }

  _errorHandler(error) {
    let errorMessage;
    if (!error || !error.status) {
      errorMessage = 'Server Not Responding';
    } else if (error.status === 401) {
      errorMessage = (error.error.message) || 'Bad Response';
    } else if (error.status === 500) {
      errorMessage = 'Server Error';
    } else {
      errorMessage = (error.error.message) || 'Bad Response';
    }

    $.notify(errorMessage, 'error');
    return throwError(error);
  }

  private addHeader() {
    return { headers: this.getHeader() };
  }

  private getHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'app_version': '1.0',
    });
    return headers;
  }

  private addHeaderForMultipart() {
    if (localStorage.getItem('access_token')) {
      const headers = new HttpHeaders();
      return { headers: headers };
    }
  }

  private addLoader(id) {
    if (id) {
      const btn = $('#' + id);
      btn.addClass('disabled');
      btn.prepend(`<i class='fa fa-spinner fa-spin'></i> `);
    }
  }

  private removeLoader(id) {
    if (id) {
      const btn = $('#' + id);
      btn.removeClass('disabled');
      btn.children('i:first').remove();
    }
  }
}
