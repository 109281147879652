import { Directive, ElementRef, HostListener, Input, Renderer2, NgZone } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input()
  public tooltipText: string | null = null;
  @Input()
  public placement = 'top';
  @Input()
  public delay = 0;
  @Input()
  public showDelay = 0;
  @Input()
  public hideDelay = 300;
  @Input()
  public zIndex = false;
  @Input()
  public validationEl: HTMLElement | null = null;
  @Input()
  public validate = false;
  @Input()
  public realSize = false;


  public tooltip: HTMLSpanElement | null = null;
  public elemPosition: ClientRect | null = null;
  public tooltipOffset = 8;
  public hideTimeoutId: NodeJS.Timer | null = null;
  public showTimeoutId: NodeJS.Timer | null = null;

  constructor(
    private host: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}

  @HostListener('focusin')
  @HostListener('mouseenter')
  @HostListener('mousemove')
  public onMouseEnter(): void {
    if (!this.tooltipText) {
      return;
    }

    this.getPosition();

    if (this.tooltip) {
      return;
    }

    const check = setInterval(() => {
      if (!document.body.contains(this.host.nativeElement)) {
        this.onMouseLeave();
        clearInterval(check);
      }
    }, 300);

    this.init();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('mousedown')
  public onMouseLeave(): void {
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (!this.tooltip || !this.tooltip.parentNode) {
      return;
    }

    this.tooltip.classList.remove('kpi-tooltip-show');

    this.zone.runOutsideAngular(() => {
      this.hideTimeoutId = setTimeout(() => {
        if (this.tooltip && this.tooltip.parentNode) {
          this.tooltip.parentNode.removeChild(this.tooltip);
          this.tooltip = null;
        }
      }, this.hideDelay);
    });
  }

  public getPosition(): void {
    this.elemPosition = this.host.nativeElement.getBoundingClientRect();
  }

  public init(): void {
    this.showDelay = this.delay || this.showDelay;
    this.tooltip = this.renderer.createElement('span');
    this.tooltip.className += `kpi-tooltip kpi-tooltip-${this.placement}`;
    this.tooltip.textContent = this.tooltipText;
    this.renderer.appendChild(document.body, this.tooltip);

    const tooltipSize: ClientRect = this.tooltip.getBoundingClientRect();
    const checkSize: number = this.getContentWidth(this.getValidationEl());
    const isHowTooltip = this.realSize ? (tooltipSize.width > checkSize) : (tooltipSize.width >= checkSize - 4);
    this.tooltip.classList.add('kpi-tooltip-sizing');

    if (isHowTooltip) {
        // after measuring tootip size with original text -> insert split text
        this.tooltip.textContent = this.prepareText(this.tooltipText);
        this.setPosition();

        if (this.showTimeoutId) {
          clearTimeout(this.showTimeoutId);
        }

        this.showDelay = this.delay || this.showDelay;

        this.zone.runOutsideAngular(() => {
          this.showTimeoutId = setTimeout(() => {
            if (!this.tooltip) {
              return;
            }

            this.tooltip.className += ' kpi-tooltip-show';
          }, this.showDelay);
        });
    } else {
      this.renderer.removeChild(document.body, this.tooltip);
    }
  }

  public setPosition(): void {
    const elemHeight: number = this.host.nativeElement.offsetHeight;
    const elemWidth: number = this.host.nativeElement.offsetWidth;
    const tooltipHeight: number = this.tooltip.clientHeight;
    const tooltipWidth: number = this.tooltip.offsetWidth;
    const scrollY: number = window.pageYOffset;

    if (this.placement === 'top') {
      this.tooltip.style.top = `${(this.elemPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset)}px`;
    }

    if (this.placement === 'bottom') {
      this.tooltip.style.top = `${(this.elemPosition.top + scrollY) + elemHeight + this.tooltipOffset}px`;
    }

    if (this.placement === 'top' || this.placement === 'bottom') {
      this.tooltip.style.left = `${(this.elemPosition.left + elemWidth / 2) - tooltipWidth / 2}px`;
    }

    if (this.placement === 'left') {
      this.tooltip.style.left = `${this.elemPosition.left - tooltipWidth - this.tooltipOffset}px`;
    }

    if (this.placement === 'right') {
      this.tooltip.style.left = `${this.elemPosition.left + elemWidth + this.tooltipOffset}px`;
    }

    if (this.placement === 'left' || this.placement === 'right') {
      this.tooltip.style.top = `${(this.elemPosition.top + scrollY) + elemHeight / 2 - this.tooltip.clientHeight / 2}px`;
    }
  }

  private getContentWidth(el: HTMLElement): number {
    return el.getBoundingClientRect().width - (this.realSize ? 0 : 32);
  }

  private getValidationEl(): HTMLElement {
    return this.validationEl || this.host.nativeElement;
  }

  private prepareText(test: string): string {
    const wordsArr: string[] = test.split(' ');
    let result = '';

    for (let i = 0; i < wordsArr.length; i++) {
      if (wordsArr[i].length > 20) {
        result += (i > 0 ? ' ' : '') + wordsArr[i].match(/.{1,20}/g).join(' ');
      } else {
        result += (i > 0 ? ' ' : '') + wordsArr[i];
      }
    }

    return result;
  }
}
