import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { users } from 'src/environments/environment';
import { ApiService } from './services/api.service';
import { IStatus } from './shared/components/logo-component/logo-component';
declare const loader: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public trudiAnimationStatus: IStatus = IStatus.default;
  public fg: FormGroup;
  public isSaved = false;
  private userId: string;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    loader.ajaxindicatorstop();
    this.fg = this.fb.group({
      newConversation: this.fb.control(false),
      newReply: this.fb.control(false),
      updates: this.fb.control(false),
      promotions: this.fb.control(false),
      unsubscribeAll: this.fb.control(false),
    });
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.getStatus();
  }

  savePreferences() {
    this.isSaved = true;
    const body = {
      newConversationEmail: this.getNewConversationValue,
      newReplyEmail: this.getNewReplyValue,
      updatesEmail: this.getUpdatesValue,
      promotionsEmail: this.getPromotionsValue
    };

    this.apiService.postAPI(users, `subscription?id=${this.userId}`, body).subscribe();
  }

  backToSetting() {
    this.isSaved = false;
    this.getStatus();
  }

  getStatus() {
    this.apiService.getAPI(users, `subscription?id=${this.userId}`).subscribe(res => {
      if (res) {
        if (!res.newConversationEmail && !res.newReplyEmail && !res.updatesEmail && !res.promotionsEmail) {
          this.fg.get('unsubscribeAll').setValue(true);
        }
        this.fg.patchValue({
          newConversation: res.newConversationEmail,
          newReply: res.newReplyEmail,
          updates: res.updatesEmail,
          promotions: res.promotionsEmail
        });
      }
    });
  }

  onCheckboxOther(event) {
    if (event.target.checked) {
      this.fg.get('unsubscribeAll').setValue(false);
    } else {
      if (!this.getNewConversationValue && !this.getNewReplyValue && !this.getUpdatesValue && !this.getPromotionsValue) {
        this.fg.get('unsubscribeAll').setValue(true);
      }
    }
  }

  onCheckboxUnsubscribeAll(event) {
    if (event.target.checked) {
      this.fg.patchValue({
        newConversation: false,
        newReply: false,
        updates: false,
        promotions: false
      });
    }
  }

  get getNewConversationValue() {
    return this.fg.get('newConversation').value;
  }
  get getNewReplyValue() {
    return this.fg.get('newReply').value;
  }
  get getUpdatesValue() {
    return this.fg.get('updates').value;
  }
  get getPromotionsValue() {
    return this.fg.get('promotions').value;
  }
  get getUnsubscribeAllValue() {
    return this.fg.get('unsubscribeAll').value;
  }
}
