import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from './components/loader/loader.component';

import { DateCasePipe } from './pipes/date-pipe';
import { FileCasePipe } from './pipes/file-pipe';
import { FileInboxCasePipe } from './pipes/file-inbox-pipe';
import { LimitLetterPipe } from './pipes/limit-string-pipe';
import { TimeAgoPipe } from './pipes/time-ago-pipe';
import { HighlightSearch } from './pipes/highlight-text';

import { GoogleplaceDirective } from './directives/google.place.directive';
import { DigitOnlyDirective } from './directives/digitOnly.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { OutsideClickListenerDirective } from './directives/outsideClickListenerDirective';
import { DpDatePickerModule } from 'ng2-date-picker';
import { LogoComponent } from './components/logo-component/logo-component';
// import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    // NgxFileDropModule,
  ],
  providers: [
    FileCasePipe,
    FileInboxCasePipe
  ],
  declarations: [
    GoogleplaceDirective,
    DigitOnlyDirective,
    TooltipDirective,
    OutsideClickListenerDirective,
    TimeAgoPipe,
    DateCasePipe,
    FileCasePipe,
    FileInboxCasePipe,
    LimitLetterPipe,
    HighlightSearch,
    LoaderComponent,
    LogoComponent
  ],
  exports: [
    GoogleplaceDirective,
    DigitOnlyDirective,
    TooltipDirective,
    OutsideClickListenerDirective,
    TimeAgoPipe,
    DateCasePipe,
    FileCasePipe,
    FileInboxCasePipe,
    LimitLetterPipe,
    HighlightSearch,
    LoaderComponent,
    LogoComponent
  ]
})
export class SharedModule {}
