import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-component',
  templateUrl: 'logo-component.html',
  styleUrls: ['./logo-component.scss'],
})
export class LogoComponent {
  @Input() status: IStatus;
  @Input() width: number;
  @Input() height: number;
  @Input() isSmallSize: boolean;

  constructor() {
    this.width = this.width ? this.width : 20;
    this.height = this.height ? this.height : 20;
  }
}

export enum IStatus {
  default = 'default',
  spinWhite = 'spinWhite',
  resizeWhite = 'resizeWhite',
  resize= 'resize',
  spin = 'spin',
}
