import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;
  private m = moment;

  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
  }

  transform(value: string, addAgo: boolean) {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'Just now';
    } else if (seconds <= 90) {
      return '1 min' + (addAgo ? ' ago' : '');
    } else if (minutes <= 45) {
      return minutes + ' min' + (addAgo ? ' ago' : '');
    } else if (minutes <= 90) {
      return '1 hour' + (addAgo ? ' ago' : '');
    } else if (hours <= 22) {
      return hours + ' hrs' + (addAgo ? ' ago' : '');
    } else if (hours <= 36) {
      return 'Yesterday';
    } else if (days <= 25) {
      return this.replaceFirst0(this.m(value).format('DD MMM'));
    } else if (days <= 45) {
      return this.replaceFirst0(this.m(value).format('DD MMM'));
    } else if (days <= 345) {
      return this.replaceFirst0(this.m(value).format('DD MMM'));
    } else if (days <= 545) {
      return this.m(value).format('DD MMM YYYY');
    } else {
      return this.m(value).format('DD MMM YYYY');
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      return 2;
    } else if (seconds < hr) {
      return 30;
    } else if (seconds < day) {
      return 300;
    } else {
      return 3600;
    }
  }

  private replaceFirst0(time: string): string {
    if (time[0] === '0') {
      return time.slice(1, time.length);
    } else {
      return time;
    }
  }
}
