import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateCase' })
export class DateCasePipe implements PipeTransform {
  private date: any;
  private currDate: any;
  public transform(str: string): string {
    if (str != null && str !== '') {
      const date = new Date(str);
      this.date = date.getTime();

      const currDate = new Date();
      this.currDate = currDate.getTime();

      const SECOND_MILLIS = 1000;
      const MINUTE_MILLIS = 60 * SECOND_MILLIS;
      const HOUR_MILLIS = 60 * MINUTE_MILLIS;
      const DAY_MILLIS = 24 * HOUR_MILLIS;

      const diff = this.currDate - this.date;
      const days = Math.floor(diff / DAY_MILLIS);
      if (diff < MINUTE_MILLIS) {
        return 'just now';
      } else if (diff < 2 * MINUTE_MILLIS) {
        return Math.floor(diff / MINUTE_MILLIS) + ' min ago';
      } else if (diff < 50 * MINUTE_MILLIS) {
        return Math.floor(diff / MINUTE_MILLIS) + ' mins ago';
      } else if (diff < 90 * MINUTE_MILLIS) {
        return '1hr ago';
      } else if (diff < 24 * HOUR_MILLIS) {
        return Math.floor(diff / HOUR_MILLIS) + ' hrs ago';
      } else if (diff < 48 * HOUR_MILLIS) {
        return '1 Day ago';
      } else {
        if (days > 7) {
          return this.getAppWeekFormat(days, str);
        } else {
          return Math.floor(diff / DAY_MILLIS) + ' days ago';
        }
      }
    } else {
      return null;
    }
  }

  getAppWeekFormat(days, date) {
    if (days !== '') {
      if (days / 7 >= 1 && days / 7 <= 4) {
        return Math.floor(days / 7) + ' week ago';
      } else {
        this.getAppDateFormat(date);
      }
    }
    return '';
  }

  getAppDateFormat(str) {
    if (str !== '') {
      const date = new Date(str);
      this.date = date.toString().split(' ');
      return date[2] + ' ' + date[1] + ' ' + date[3];
    }
    return '';
  }
}
