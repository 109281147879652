import {
    Directive,
    ElementRef,
    Renderer2,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';

@Directive({
    selector: '[appOutsideClickListener]'
})
export class OutsideClickListenerDirective implements OnInit, OnDestroy {
    @Input() public trackEl: HTMLElement | null = null;
    @Output() public clickOutside = new EventEmitter<MouseEvent>();
    private clickListener: () => void = null;

    constructor(
        private host: ElementRef,
        private renderer: Renderer2
    ) { }
    public ngOnInit(): void {
        this.clickListener = this.renderer.listen(this.trackEl || document, 'click', (e: MouseEvent) => {
            this.handleClick(e);
        });
    }
    public ngOnDestroy(): void {
        if (typeof this.clickListener !== 'function') {
            return;
        }
        this.clickListener();
    }

    private handleClick(e: MouseEvent): void {
        const clickedInside = this.host.nativeElement.contains(e.target);

        if (e.which === 3 || clickedInside) {
            return;
        }

        this.clickOutside.emit(e);
    }
}
