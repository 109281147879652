export const environment = {
  production: false,
};

export const apiKey = 'https://api.attic.trulet.com';
export const auth = 'https://api.attic.trulet.com/auth/';
export const users = 'https://api.attic.trulet.com/users/';
export const properties = 'https://api.attic.trulet.com/properties/';
export const conversations = 'https://api.attic.trulet.com/conversations/';
export const agencies = 'https://api.attic.trulet.com/agencies/';
export const voxeet = 'https://webapp.attic.trulet.com';
export const webSocketUrl = 'wss://msg.attic.trulet.com';
export const env = 'cellar';
export const mixpanelToken = '29be64a0beb78a79b1c1ef41bdc5c409';
export const featureVersion = '1.00';
export const currentVersion = '1.00';
export const fileLimit = 25;
